<template>
  <div class="content">
    <div class="flex gap-8">
      <div class="bg-gray-100 border border-gray-200 px-4 py-3 rounded-sm">
        <button-select
          v-model="tab"
          :options="tabs"
          size="sm"
          class="flex-col gap-2"
        />
      </div>

      <div class="flex-1">
        <component :is="tabComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const Accounts = () => import('@/components/admin/Accounts.vue');

export default {
  name: 'Admin',
  components: {
    ButtonSelect,
    Accounts,
  },
  computed: {
    tabComponent() {
      return this.tabs[this.tab].component;
    },
  },
  data() {
    return {
      tab: 0,
      tabs: [
        { text: 'Accounts', value: 0, component: Accounts },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
